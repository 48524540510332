import React, { useCallback, useState, useMemo, useRef, useEffect, useContext } from "react";
import ReactPlayer from 'react-player';
import { Slider, Tooltip, Grid, Typography } from '@mui/material';


import PlayArrow from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";

import { GlobalContext } from "../Wrapper";

const Player = React.forwardRef(({
    isVideo,
    fullscreenMode,
    showTimeControls = false,
    onProgress: onExternalProgress,
    onEnded: onExternalEnded,
    onPlay: onExternalPlay,
    onPause: onExternalPause,
    ...props }, playerRef) => {

    if (!playerRef) playerRef = React.createRef();

    const controlsStyle = {
        backgroundColor: 'var(--playerBg)',
        zIndex: '65535'
    };

    if (fullscreenMode) {
        Object.assign(controlsStyle, {
            position: 'fixed',
            padding: '12px 6px 6px 6px',
            bottom: 0,

        });
    } else {
        Object.assign(controlsStyle, {
            //position: 'absolute',

        });
    }

    const playerConfig = {
        file: {
            attributes: {
                controlsList: 'nodownload noplaybackrate',
                controls: false,
            },
        }
    };

    const [playing, setPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [progress, setProgress] = useState(0);
    const [scrubbing, setScrubbing] = useState(false);

    const PlayerIcon = useMemo(() => playing ? Pause : PlayArrow, [playing]);

    const { player: currentPlayer, setPlayer } = useContext(GlobalContext);

    useEffect(() => {
        if (!playerRef.current) return;

        const internalPlayer = playerRef.current?.getInternalPlayer();

        if (currentPlayer !== internalPlayer) {
            if (internalPlayer) internalPlayer.pause();
        }
    }, [playerRef, currentPlayer]);

    const onProgress = useCallback((e) => {
        //if (onExternalProgress) onExternalProgress(e); - bound to timeupdate now for better granularity
        if (scrubbing) return;

        if (playerRef.current && duration > 0) {
            setProgress(Math.round(playerRef.current.getCurrentTime() / duration * 1000) / 10);
        } else {
            //  setProgress(0);
        }
    }, [setProgress, duration, playerRef, scrubbing]);


    const onStart = useCallback(() => {
        if (playerRef.current) {
            setDuration(playerRef.current.getDuration());
            onPlay();
        }
    }, [setDuration, playerRef]);

    const onPlay = useCallback(() => {
        setPlaying(true);
        if (onExternalPlay) onExternalPlay();
    }, [playerRef, setPlaying]);

    const onPause = useCallback(() => {
        setPlaying(false);
        if (onExternalPause) onExternalPause();
    }, [playerRef, setPlaying]);

    const onEnded = useCallback((e) => {
        setPlaying(false);
        if (onExternalEnded) onExternalEnded(e);
    }, [playerRef, setPlaying, onExternalEnded]);


    const onPlayIcon = useCallback(() => {
        if (!playerRef.current) return;
        const player = playerRef.current.getInternalPlayer();
        setPlayer(player);

        playing ? player.pause() : player.play();

    }, [playing, playerRef]);

    const ValueLabelComponent = (props) => {//TODO - break scrubbing on mobile, probably useEffect if we want this
        const { children, open, value } = props;

        return (
            <Tooltip open={open} enterTouchDelay={10} placement="top" title={value} PopperProps={{ style: { zIndex: 65535 } }}>
                {children}
            </Tooltip>
        );
    };

    const valueFormat = (value) => {
        //const title=`${duration} ${progress} ${duration*value/100}`;
        const curTime = duration * value / 100;
        const s = `${parseInt(curTime % 60)}`.padStart(2, '0');
        const m = parseInt((curTime / 60) % 60);

        const durS = `${parseInt(duration % 60)}`.padStart(2, '0');
        const durM = parseInt((duration / 60) % 60);

        return `${m}:${s} / ${durM}:${durS}`;
    }

    const rewind = () => {
        const pos = playerRef.current?.getCurrentTime();

        playerRef.current?.seekTo(pos > 10 ? pos - 10 : 0);
    }

    const forward = () => {
        const pos = playerRef.current?.getCurrentTime();
        const duration = playerRef.current?.getDuration();
        playerRef.current?.seekTo(Math.floor(pos + 10, duration));
    }

    return <div className="">
        <ReactPlayer width='100%' height='100%'
            ref={playerRef}
            config={playerConfig}
            onProgress={onProgress}
            onStart={onStart}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={onEnded}
            onTimeUpdate={onExternalProgress}
            pip={false /* TODO - prop true */}
            progressInterval={1000}
            {...props}
        />
        <div >
            <Grid className="player-controls" container style={controlsStyle} spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="flex-start">

                <Grid item container xs={11} style={{ height: showTimeControls ? '72px' : '32px' }}>
                    {showTimeControls === true && <>
                        <Grid item xs={2} />
                        <Grid item xs={4} ><Typography onClick={rewind} sx={{ marginLeft: "-1px", marginTop: "12px", marginBottom: "14px", fontWeight: "700", fontSize: "small" }}>-10</Typography></Grid>
                        <Grid item xs={6} align="right"><Typography onClick={forward} sx={{ marginRight: "-6px", marginTop: "12px", marginBottom: "14px", fontWeight: "700", fontSize: "small" }}>+10</Typography></Grid>
                    </>
                    }
                    <Grid container mt={showTimeControls ? -1 : 0}>
                        <Grid item xs={2} pl={1}>
                            <PlayerIcon className="controlsIcon--small" onClick={onPlayIcon} />
                        </Grid>
                        <Grid item xs={10}>
                            <Slider
                                size="small"
                                style={{ padding: '13px 0px' }}
                                aria-label="time-indicator"
                                value={progress}
                                defaultValue={0}
                                valueLabelFormat={valueFormat}
                                valueLabelDisplay='auto'
                                step={0.01}
                                onChange={(_, value) => {
                                    if (!scrubbing) setScrubbing(true);
                                    setProgress(value);
                                }}
                                onChangeCommitted={(_, val) => {
                                    playerRef.current && playerRef.current.seekTo(val / 100, 'fraction');
                                    setProgress(val);
                                    setScrubbing(false);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
});

export default Player;


