import React from 'react';
import { styled } from '@mui/material/styles';
import Collapsible from 'react-collapsible';
import { useTheme } from "@mui/material/styles";

import Videoplayer from "./Videoplayer/";
import Imageslider from "./Imageslider";
import ImagesliderAdvanced from "./ImagesliderAdvanced";
import InlinePlayer from "./InlinePlayer";

import Imageplayer from "./Imageplayer";
import Text from "./Text";
import ThreeSixtyViewer from "./ThreeSixtyViewer";
import Map from "./Map.js";
import Comparisonslider from './Comparisonslider';
import CrowdsignalPoll from './CrowdsignalPoll';

//css loaded here vs in components to avoid dups
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../css/collapsible.css";
import "../css/richtext.css";

const PREFIX = 'ComponentsWrapper';

const classes = {
    padding: `${PREFIX}-padding`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.padding}`]: {
        padding: theme.spacing(1)
    },

}));

const ComponentsWrapper = (props) => {
    const { entity } = props;
    const theme = useTheme();

    return (
        <Root className={theme.palette.mode}>{
            entity.Components.map((component, n) => {
                const Wrap = component.collapsible ?
                    ({ children, component }) =>
                        <Collapsible trigger={component.label || 'Click here'} open={!component.collapsed}>
                            {children}
                        </Collapsible>

                    :

                    ({ children }) => <>{children}</>
                return <Wrap key={`${component.strapi_component}${n}`} component={component}>{(() => {
                    switch (component.strapi_component) {
                        case 'component.videoplayer':
                            return <Videoplayer component={component} entity={entity} key={`component-${n}`} {...props} />

                        case 'component.imageslider':
                            return <Imageslider component={component} entity={entity} {...props} />
                        case 'component.imageslider-advanced':
                            return <ImagesliderAdvanced component={component} entity={entity} {...props} />
                        case 'component.inlineplayer':
                            return <div className={classes.padding} key={`component-${n}`} >
                                <InlinePlayer component={component} entity={entity} {...props} />
                            </div>
                        case 'component.imageplayer':
                            return <Imageplayer component={component} entity={entity} key={`component-${n}`} {...props} />

                        case 'component.text':
                            return <div className={classes.padding} key={`component-${n}`} ><Text component={component} entity={entity} {...props} /></div>

                        case 'component.three-sixty-viewer':
                            return <ThreeSixtyViewer component={component} entity={entity} key={`component-${n}`} {...props} />
                        case 'component.map':
                            return <Map component={component} entity={entity} key={`component-${n}`} {...props} />
                        case 'component.comparisonslider':
                            return <Comparisonslider component={component} entity={entity} key={`component-${n}`} {...props} />
                        case 'component.crowdsignal-poll':
                            return <CrowdsignalPoll component={component} entity={entity} key={`component-${n}`} {...props} />
                        default:
                            return <></>;
                    }
                })()}
                </Wrap>
            })
        }</Root>
    );
}

export default ComponentsWrapper
