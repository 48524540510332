import React from "react";
import _ from "lodash";

import { getImage } from "gatsby-plugin-image";

import parseSrcSet from "parse-srcset";

import { getEntityUrl } from "../../util";

const getUrls = (nodes, files, defaultLocale) => {

    const mediaUrls = [];
    let pageUrls = [];
    const addMediaUrls = (imgFile) => {
        if (!imgFile) return;

        const img = getImage(imgFile);

        mediaUrls.push(...parseSrcSet(img.images.fallback.srcSet).map(s => s.url));
        img.images.sources.forEach(src => {
            // mediaUrls.push(src.src);
            mediaUrls.push(...parseSrcSet(src.srcSet).map(s => s.url));
        });
        mediaUrls.push(img.images.fallback.src);
    }

    nodes.forEach(e => {
        addMediaUrls(e.smallImage?.localFile);
        addMediaUrls(e.largeImage?.localFile);

        const sliderComponent = _.find(e.Components, { strapi_component: 'component.imageslider' });
        _.each(sliderComponent?.images, i => addMediaUrls(_.find(files.nodes, { id: i.localFile___NODE })));

        const sliderAudioUrl = _.find(files.nodes, { id: sliderComponent?.audio?.localFile___NODE })?.publicURL
        if (sliderAudioUrl) mediaUrls.push(sliderAudioUrl);


        const videoComponent = _.find(e.Components, { strapi_component: 'component.videoplayer' });
        const videoUrl = _.find(files.nodes, { id: videoComponent?.video?.localFile___NODE })?.publicURL
        if (videoUrl) mediaUrls.push(videoUrl);

        //TODO - test
        const imgPlayerComponent = _.find(e.Components, { strapi_component: 'component.imageplayer' });
        _.each(imgPlayerComponent?.image, i => addMediaUrls(_.find(files.nodes, { id: i.image?.localFile___NODE })));

        const playerAudioUrl = _.find(files.nodes, { id: imgPlayerComponent?.audio?.localFile___NODE })?.publicURL
        if (playerAudioUrl) mediaUrls.push(playerAudioUrl);


        const pageUrl = getEntityUrl({ entity: e, defaultLocale });//TODO - test

        pageUrls.push(pageUrl);
    });

    return { pageUrls, mediaUrls: _.uniq(mediaUrls) };
};

const traverseChildren = (children, entities) => {
    const rv = [];
    _.each(children, c => {
        const entity = _.find(entities, { identifier: c.identifier, locale: c.locale });
        const offlineComponent = getOfflineComponent(entity);
        
        if (!offlineComponent?.exclude) rv.push(entity);

        if (entity?.strapiChildren) rv.push(...traverseChildren(entity.strapiChildren, entities));
    });
    return rv;
};

const getOfflineComponent = (entity) => {
    if (!entity?.Components) return;
    return _.find(entity.Components, { strapi_component: 'component.offline' });
};


const preloadEntityPages = async ({ pageUrls }) => {
    if (typeof window !== `undefined`) {
        await navigator.serviceWorker.ready;
        pageUrls.forEach(async url => {//TODO - concurrency max?

            if (process.env.NODE_ENV !== `production`) console.log(`dowloading ${url}`);

            const link = document.createElement(`link`)
            link.rel = `prefetch`
            link.href = url;

            link.onload = link.remove
            link.onerror = link.remove

            document.head.appendChild(link);
        });
    }
};

export { getUrls, getOfflineComponent, preloadEntityPages, traverseChildren };